// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContact from "../../../../models/UserContact.res.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CreateBareMetalProjectGeneralInformation from "./CreateBareMetalProjectGeneralInformation.res.js";

function CreateBareMetalProjectGeneralInformation__JsBridge$default(props) {
  var railsContext = props.railsContext;
  var jsProps = props.jsProps;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projectId = React.useMemo((function () {
          return Caml_option.nullable_to_opt(jsProps.projectId);
        }), [jsProps.projectId]);
  var category = Project.Category.fromString(jsProps.category, jsProps.subcategory);
  var title = React.useMemo((function () {
          return Caml_option.nullable_to_opt(jsProps.title);
        }), [jsProps.title]);
  var description = React.useMemo((function () {
          return Caml_option.nullable_to_opt(jsProps.description);
        }), [jsProps.description]);
  var alreadyUploadedDocuments = React.useMemo((function () {
          return Belt_Array.map(jsProps.documents, Project.$$Document.fromJs);
        }), [jsProps.documents]);
  var businessName = React.useMemo((function () {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(jsProps.businessName), "");
        }), [jsProps.businessName]);
  var businessContactName = React.useMemo((function () {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(jsProps.businessContactName), "");
        }), [jsProps.businessContactName]);
  var businessEmail = React.useMemo((function () {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(jsProps.businessEmail), "");
        }), [jsProps.businessEmail]);
  var businessPhone = React.useMemo((function () {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(jsProps.businessPhone), "");
        }), [jsProps.businessPhone]);
  var headquartersAddress = React.useMemo((function () {
          return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(jsProps.headquartersAddress), "");
        }), [jsProps.headquartersAddress]);
  var contacts = React.useMemo((function () {
          return Belt_Array.map(jsProps.contacts, UserContact.fromJs);
        }), [jsProps.contacts]);
  var agency = React.useMemo((function () {
          return Caml_option.nullable_to_opt(jsProps.agency);
        }), [jsProps.agency]);
  var status = React.useMemo((function () {
          return Project.Status.fromJson(jsProps.status);
        }), [jsProps.status]);
  var configurations = React.useMemo((function () {
          return Belt_Array.map(jsProps.bareMetalConfigurations, CreateBareMetalProjectGeneralInformation.Configuration.fromJson);
        }), []);
  return JsxRuntime.jsx(CreateBareMetalProjectGeneralInformation.make, {
              userLoginStatus: railsContext$1.userLoginStatus,
              userRole: railsContext$1.userRole,
              projectId: projectId,
              category: category,
              title: title,
              configurations: configurations,
              description: description,
              alreadyUploadedDocuments: alreadyUploadedDocuments,
              businessName: businessName,
              businessContactName: businessContactName,
              businessEmail: businessEmail,
              businessPhone: businessPhone,
              headquartersAddress: headquartersAddress,
              contacts: contacts,
              isAgent: jsProps.isAgent,
              agency: agency,
              agencyOptions: jsProps.agencyOptions,
              canAgencyBeManaged: jsProps.canAgencyBeManaged,
              mobile: railsContext$1.mobile,
              status: status
            });
}

var $$default = CreateBareMetalProjectGeneralInformation__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
